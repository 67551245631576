import axios from 'axios'
import React, { useContext, useState } from 'react'
import { AppContext } from '../App'
import { CopyAll } from '@mui/icons-material'
import { sha256 } from 'js-sha256'
import { JsonView, allExpanded, darkStyles, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

export default function CheckStatusDana() {
  const [orderId, setOrderId] = useState("")
  const [transaction, setTransaction] = useState(null)
  const [error, setError] = useState("")
  const context = useContext(AppContext)

  function getTableData(table) {
    let text = '';
    const rows = table.querySelectorAll('tr');

    // Iterate through rows and cells
    for (const row of rows) {
      const cells = row.querySelectorAll('th, td');
      for (const cell of cells) {
        text += cell.textContent.trim() + '\t'; // Use tabs for separation
      }
      text += '\n'; // Add newline after each row
    }

    return text;
  }

  function copytable(el) {
    const table = document.getElementById('table');
    const text = getTableData(table)
    navigator.clipboard.writeText(text).then(() => {
      alert('Table data copied to clipboard!');
    })
      .catch(err => {
        alert('Failed to copy table data');
        // Handle clipboard permission errors or other issues (optional)
      });
  }

  async function fetchCheckStatus(event) {
    event.preventDefault()
    const timestamp = `${new Date().getTime()}`
    const headers = {
      "order_id": orderId,
      "Authorization": "Basic ZGFzaDokJSREJEFTSA==",
      "x-signature": sha256(`${timestamp}:${"p1Ro"}`),
      "x-timestamp": timestamp
    }
    
    try {
      setError("")
      setTransaction(null)

      const url = "https://us-central1-doc-basic.cloudfunctions.net/dashboard2gen/v1/track-dana-qris?originalReferenceNo="+orderId
      const transactionResponse = await axios.get(url,{
        headers
      })
      setTransaction(transactionResponse.data.data)
    } catch (error) {
      setError("Order Id tidak ditemukan")
    }
  }

  return (
    <div style={{ padding: "20px" }}>
      <h2>Check Status</h2>
      <div>
        <form onSubmit={fetchCheckStatus}>
          <label>Transaksi ID</label>
          <div style={{ display: "flex" }}>
            <input
              className=''
              name="orderId"
              type='text'
              placeholder='Input transaction Id'
              value={orderId}
              onChange={(event) => setOrderId(event.target.value)}
            />
            <button style={{ padding: "0px 10px 0px 10px", marginLeft: "10px" }} type='button' onClick={e=> {
              setTransaction(null)
              setOrderId("")
            }}>Clear</button>
            <button style={{ padding: "0px 10px 0px 10px", marginLeft: "10px" }} type='submit'>Submit</button>
          </div>
        </form>
      </div>
      <div style={{}}>
        {error && (
          <p>{error}</p>
        )}
        {transaction && (
          <>
            <button onClick={copytable} style={{ marginBottom: "10px", display: "flex", justifyContent: "center", padding: "5px" }}>
              <CopyAll></CopyAll>
              Copy
            </button>
            <div>
            <JsonView data={transaction} shouldExpandNode={allExpanded} style={{...darkStyles, width: "100%"}} />
              </div>
          </>
        )}


      </div>
    </div>
  )
}
