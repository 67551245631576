import axios from 'axios'
import React, { useContext, useState } from 'react'
import { AppContext } from '../App'
import { CopyAll } from '@mui/icons-material'
import { sha256 } from 'js-sha256'

export default function CheckStatusSnap() {
  const [orderId, setOrderId] = useState("")
  const [transaction, setTransaction] = useState(null)
  const [error, setError] = useState("")
  const context = useContext(AppContext)

  function getTableData(table) {
    let text = '';
    const rows = table.querySelectorAll('tr');

    // Iterate through rows and cells
    for (const row of rows) {
      const cells = row.querySelectorAll('th, td');
      for (const cell of cells) {
        text += cell.textContent.trim() + '\t'; // Use tabs for separation
      }
      text += '\n'; // Add newline after each row
    }

    return text;
  }

  function copytable(el) {
    const table = document.getElementById('table');
    const text = getTableData(table)
    navigator.clipboard.writeText(text).then(() => {
      alert('Table data copied to clipboard!');
    })
      .catch(err => {
        alert('Failed to copy table data');
        // Handle clipboard permission errors or other issues (optional)
      });
  }

  async function fetchCheckStatusSnap(event) {
    event.preventDefault()
    const urlParams = new URLSearchParams(window.location.search);
    const nmid = urlParams.get('nmid');
    const clientId = urlParams.get('clientId');
    const timestamp = `${new Date().getTime()}`
    const headers = {
      "client_id": clientId,
      "Authorization": "Basic ZGFzaDokJSREJEFTSA==",
      "x-signature": sha256(`${timestamp}:${"p1Ro"}`),
      "x-timestamp": timestamp
    }

    const orderId = event.target.elements.orderId.value;

    try {
      setError("")
      setTransaction(null)

      const url = "https://us-central1-doc-basic.cloudfunctions.net/dashorder2gn/v1/nobu-qr-mpm-callback"

      const transactionResponse = await axios.post(url, { orderId: orderId }, {
        headers
      })

      setTransaction(transactionResponse.data)
    } catch (error) {
      setError("Order Id tidak ditemukan")
    }
  }

  return (
    <div style={{ padding: "20px" }}>
      <h2>Check Status SNAP</h2>
      <div>
        <form onSubmit={fetchCheckStatusSnap}>
          <label>Order ID</label>
          <div style={{ display: "flex" }}>
            <input
              className=''
              name="orderId"
              type='text'
              placeholder='Input order Id'
              value={orderId}
              onChange={(event) => setOrderId(event.target.value)}
            />
            <button style={{ padding: "0px 10px 0px 10px", marginLeft: "10px" }} type='button' onClick={e => {
              setTransaction(null)
              setOrderId("")
            }}>Clear</button>
            <button style={{ padding: "0px 10px 0px 10px", marginLeft: "10px" }} type='submit'>Submit</button>
          </div>
        </form>
      </div>
      <div style={{ marginTop: "40px", display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
        {error && (
          <p>{error}</p>
        )}

        {transaction && (
          <>
            <button onClick={copytable} style={{ marginBottom: "10px", display: "flex", justifyContent: "center", padding: "5px" }}>
              <CopyAll></CopyAll>
              Copy
            </button>

            <table id='table' style={{ backgroundColor: "#cecece", padding: "15px", width: "100%" }}>
              <thead></thead>
              <tbody>
                <tr>
                  <td>Order ID</td>
                  <td>: </td>
                  <td>{transaction.orderId}</td>
                </tr>
                <tr>
                  <td>Payment Status</td>
                  <td>: </td>
                  <td> {transaction.data.transactionStatusDesc}</td>
                </tr>
                <tr>
                  <td>Transaction No</td>
                  <td>: </td>
                  <td> {transaction.data.originalPartnerReferenceNo}</td>
                </tr>
                <tr>
                  <td>Amount</td>
                  <td>: </td>
                  <td> {transaction.data.amount.currency}. {transaction.data.amount.value}</td>
                </tr>
                <tr>
                  <td>Payment Date</td>
                  <td>: </td>
                  <td> {transaction.data.paidTime}</td>
                </tr>
                <tr>
                  <td>Payment Reference No</td>
                  <td>: </td>
                  <td> {transaction.data.additionalInfo.paymentReferenceNo}</td>
                </tr>
              </tbody>
              <tfoot></tfoot>
            </table>
          </>
        )}


      </div>
    </div>
  )
}
