import axios from 'axios'
import React, { useContext, useState } from 'react'
import { AppContext } from '../App'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import DataTable from 'react-data-table-component';
import moment from "moment"
import { url } from '../BaseUrl';
import { ButtonBase } from '@mui/material';

export default function Mutation() {
    const [orderId, setOrderId] = useState("")
    const [transaction, setTransaction] = useState([])
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [error, setError] = useState("")
    const context = useContext(AppContext);
    const [nmid, clientId] = context.nmid.split(":")

    async function fetchCheckStatus(event) {
        console.log("masuk")
        event.preventDefault()
        
        let uri = `${url}/mutation?startDate=${startDate}&endDate=${endDate}&clientId=${clientId}`
        try {
            setError("")
            setTransaction([])

            const transactionResponse = await axios.get(uri, {})
            console.log(transactionResponse.data.docs)
            setTransaction(transactionResponse.data.docs)
        } catch (error) {
            setError("Order Id tidak ditemukan")
        }
    }

    async function exportExcel(){
        const [nmid, clientId] = context.nmid.split(":")
        let uri = `${url}/mutation?startDate=${startDate}&endDate=${endDate}&clientId=${clientId}`
        try {
            setError("")
            setTransaction([])

            const transactionResponse = await axios.get(uri, {})
            console.log(transactionResponse.data.docs)
            setTransaction(transactionResponse.data.docs)
        } catch (error) {
            setError("Order Id tidak ditemukan")
        }
    }

    return (
        <div style={{ padding: "20px", width: "100%" }}>
            <h2>Mutation</h2>
            <i style={{ fontSize: "12px", color: "red"}}>Mutasi harian maksimum 31 hari yang lalu</i>
            <div style={{ marginBottom: "20px", display: "flex", justifyContent:  "space-between" }}>
                <form onSubmit={fetchCheckStatus}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginTop: "20px", display: "flex", gap: "10px" }}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker label="Start Date" format="DD-MM-YYYY" onChange={e => setStartDate(moment(e).toDate())}/>
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker label="End Date" format="DD-MM-YYYY" onChange={e => setEndDate(moment(e).toDate())}/>
                        </LocalizationProvider>
                    </div>
                    
                        <button style={{ padding: "0px 30px 0px 30px", marginTop: "20px", marginLeft: "10px", height: "53px" }} type='submit'>Submit</button>
                    </div>
                </form>
                <div style={{ height: "38px", width: "60px", display: "flex", alignItems: "flex", backgroundColor: "#97da76", cursor:"pointer", padding:"3px 3px"}}>
                    <a href={`${url}/mutation/download?startDate=${startDate}&endDate=${endDate}&clientId=${clientId}`} target="_blank">
                        <ButtonBase onClick={exportExcel}>Export Excel</ButtonBase>
                    </a>
                </div>
            </div>
            <DataTable
                columns={[
                    {
                        name: 'Transaction Date',
                        selector: row => moment(row.piroBase.transactionDate).format("DD-MM-YYYY HH:mm:ss"),
                        width: "175px",
                        style: {
                            color: 'rgba(0,0,0,.54)',
                        },
                    },
                    {
                        name: 'TX',
                        selector: row => row.piroBase.transactionId,
                        width: "490px",
                        style: {
                            color: 'rgba(0,0,0,.54)',
                        },
                    },
                    {
                        name: 'Type',
                        selector: row => row.type,
                        style: {
                            color: 'rgba(0,0,0,.54)',
                        },
                    },
                    {
                        name: 'Order ID',
                        // selector: row => row?.piroBase?.transactionRequest?.trigger?.data?.orderId ?? row?.piroBase?.transactionId,
                        selector: row => row.type == "RTOL" ? row?.piroBase?.transactionRequest?.partnerReferenceNo : row?.piroBase?.transactionId,
                        // selector: row => row?.piroBase?.transactionRequest?.partnerReferenceNo?.originalReferenceNo,
                        width: "335px",
                        style: {
                            color: 'rgba(0,0,0,.54)',
                        },
                    },
                    {
                        name: 'Debit',
                        selector: row => !row.piroBase.debit ? <span>Rp 0</span> : <span style={{ color: "red" }}>{row.piroBase.debit.toLocaleString(
                            "id-ID",
                            { style: "currency", currency: "IDR" }
                        )}</span>,
                        style: {
                            color: 'rgba(0,0,0,.54)',
                        },
                    },
                    {
                        name: 'Credit',
                        selector: row => !row.piroBase.credit ? <span>Rp 0</span> : <span style={{ color: "green" }}>{row.piroBase.credit.toLocaleString(
                            "id-ID",
                            { style: "currency", currency: "IDR" }
                        )}</span>,
                        style: {
                            color: 'rgba(0,0,0,.54)',
                        },
                    },
                ]}
                data={transaction}
                // customStyles={customStyles}
                highlightOnHover
                pointerOnHover
            // theme='solarized'
            />
        </div>
    )
}
